<template>
  <div :class="`bg-white h-100 ${isWide ? 'row w-100' : ''}`">
    <div :class="`h-100 ${isWide ? 'col-sm d-flex flex-column' : 'mt-5 mx-2'}`">
      <img :class="`${isMobile ? 'm-5' : 'm-10'} pointer`" :src="logoDark" :width="isMobile ? 150 : 200" :height="isMobile ? 35 : 45" @click="$router.push(`/`)" />
      <div :class="`my-auto ${isWide ? 'w-75 mx-auto' : 'w-100'} p-5`">
        <div class="my-10 center">
          <span class="font-25 font-bold">{{ $t('welcome') }}</span>
        </div>
        <LoginComponent></LoginComponent>
        <div :class="`${isWide ? 'mt-20' : 'mt-10'} d-flex justify-content-center vertical-center`">
          <span>{{ $t('do_not_have_an_account') }}</span>
          <v-btn text small @click="$router.push(`select_user_type`)">
            <span><u class="font-15 color-pink">{{ $t('register') }}</u></span>
          </v-btn>
        </div>
      </div>
      <TermsComponent></TermsComponent>
    </div>
    <div class="col-sm right-div" v-if="isWide" />
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import LoginComponent from "@/view/pages/auth/LoginComponent.vue";
import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

import logoDark from '@/assets/logo/logo_dark.png';

export default {
  name: 'Login',
  components: {
    LoginComponent,
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      logoDark
    }
  }
};
</script>